import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import {
  onSignInSuccess,
  onSignOutSuccess,
  setPermissions,
} from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { useLocation, useNavigate } from "react-router-dom";
import { login } from "service/authService";

function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { token, signedIn } = useSelector((state) => state.auth.session);
  const entryPath = localStorage.getItem("entryPath");
  const signIn = async (data) => {
    try {
      const resp = await login(data);
      if (resp?.success) {
        const token = resp?.data?.token;
        const permissions = resp?.permissions;

        dispatch(onSignInSuccess(token));
        // dispatch(setUser(resp?.data));
        dispatch(
          setUser({
            id: resp?.data.id ? resp?.data.id : "",
            username: resp?.data.name ? resp?.data.name : "user",
            email: resp?.data.email ? resp?.data.email : "",
            branch: resp?.data?.branch ? resp?.data?.branch : {},
            role: resp?.data?.role?.role ? resp?.data?.role?.role : "",
            code: resp?.data?.userCode ? resp?.data?.userCode : "",
            authority: resp?.data?.role?.role
              ? resp?.data.role.role.split(" ")
              : [""],
            token: resp?.data?.token ? resp?.data?.token : "",
          })
        );
        dispatch(setPermissions(permissions));
        window.location.reload();

        navigate(entryPath ? entryPath : appConfig.authenticatedEntryPath);
        return {
          status: "success",
          message: "",
        };
      }
    } catch (err) {
      console.log(err);
      return {
        status: "failed",
        message:
          err?.response?.data?.error?.message ||
          err?.response?.data?.error ||
          err?.response?.data?.message ||
          err.toString(),
      };
    }
  };

  const handleSignOut = () => {
    localStorage.clear();
    localStorage.setItem("entryPath", location.pathname);
    window.location.reload();
    dispatch(setPermissions());
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
  };

  const signOut = async () => {
    handleSignOut();
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signOut,
  };
}

export default useAuth;
