import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  branchId: "",
  branchName: "",
  branchCode: "",
};

export const branchSlice = createSlice({
  name: "auth/branch",
  initialState,
  reducers: {
    setBranch: (_, action) => action.payload,
  },
});

export const { setBranch } = branchSlice.actions;

export default branchSlice.reducer;
