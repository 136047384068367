import { combineReducers } from "@reduxjs/toolkit";
import session from "./sessionSlice";
import user from "./userSlice";
import branch from "./branchSlice";

const reducer = combineReducers({
  session,
  user,
  branch,
});

export default reducer;
